<template>
  <el-card v-loading="loading" shadow="never" class="search-wrapper">
    <el-form ref="searchFormRef" :inline="true" :model="searchData">
      <el-form-item prop="key" label="放款对象">
        <el-input v-model="searchData.key" placeholder="请输入" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :icon="Search" @click="handleSearch"
          >查询</el-button
        >
        <el-button :icon="Refresh" @click="resetSearch">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>
  <el-card v-loading="loading" shadow="never">
    <div>
      <table class="analysisTb">
        <tr>
          <th>总投入</th>
          <th>总放款</th>
          <th>未放款</th>
          <th>总回款(成本)</th>
          <th>总利润</th>
          <th>勇哥利润</th>
          <th>阿利利润</th>
          <th>Miske利润</th>
        </tr>
        <tr>
          <td>{{ totalAmount }}</td>
          <td>{{ totalPay }}</td>
          <td>{{ (totalAmount ?? 0) - (totalPay ?? 0) }}</td>
          <td>{{ totalIncome }}</td>
          <td>{{ totalProfit }}</td>
          <td>{{ (totalProfit ?? 0) * 0.2 }}</td>
          <td>{{ (totalProfit ?? 0) * 0.4 }}</td>
          <td>{{ (totalProfit ?? 0) * 0.4 }}</td>
        </tr>
      </table>
    </div>
    <div class="toolbar-wrapper">
      <div>
        <el-button
          type="primary"
          :icon="CirclePlus"
          @click="mainDialogVisible = true"
          >新增放款</el-button
        >
      </div>
      <div>
        <el-tooltip content="刷新当前页">
          <el-button
            type="primary"
            :icon="RefreshRight"
            circle
            @click="getMainTableData"
          />
        </el-tooltip>
      </div>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        :border="true"
        :summary-method="getSummaries"
        show-summary
        style="width: 100%"
        :header-row-style="{ 'font-weight': 'bold' }"
        :cell-style="{ 'font-weight': 'bold' }"
      >
        <el-table-column type="expand">
          <template #default="props">
            <div m="4">
              <el-table
                :data="props.row.subtables"
                :border="true"
                :summary-method="getSubSummaries"
                show-summary
              >
                <el-table-column label="回款时间" prop="createTime" />
                <el-table-column label="成本" prop="costAmount" />
                <el-table-column label="利润" prop="profitAmount" />
                <el-table-column label="状态">
                  <template #default="scope">
                    <el-tag
                      v-if="scope.row.status === 0"
                      :key="scope.row.status"
                      @click="showApprovalDialog(scope.row)"
                      type="warning"
                      effect="dark"
                      round
                    >
                      待审核
                    </el-tag>
                    <el-tag
                      v-if="scope.row.status === 1"
                      :key="scope.row.status"
                      type="primary"
                      effect="dark"
                      round
                    >
                      已回款
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="备注" prop="remark" />
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button
              v-if="scope.row.status == 0"
              type="warning"
              size="small"
              @click="handleRecycle(scope.row)"
              >回款</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template #default="scope">
            <div v-if="scope.row.createTime == currentDate">
              <el-tag type="error" effect="dark" round> 今日新加 </el-tag>
            </div>
            <div v-else>
              <el-tag
                v-if="scope.row.status == 1"
                type="success"
                effect="dark"
                round
              >
                清
              </el-tag>
              <el-tag
                v-if="
                  scope.row.subtables.some(
                    (u) => u.createTime === currentDate
                  ) && scope.row.status == 0
                "
                type="primary"
                effect="dark"
                round
              >
                今日已回
              </el-tag>
              <el-tag
                v-else-if="scope.row.status == 0"
                type="warning"
                effect="dark"
                round
              >
                今日未回
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="日期" prop="createTime" />
        <el-table-column label="对象" prop="name" />
        <el-table-column label="金额" prop="amount" />
        <el-table-column label="周期" prop="cycle" />
        <el-table-column label="放款人" prop="createUser" />
        <el-table-column label="备注" prop="remark" />
      </el-table>
      <el-pagination
        background
        :layout="paginationData.layout"
        :page-sizes="paginationData.pageSizes"
        :total="paginationData.total"
        :page-size="paginationData.pageSize"
        :currentPage="paginationData.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-card>

  <!-- 新增/修改 -->
  <el-dialog
    v-model="mainDialogVisible"
    @closed="resetForm"
    title="新增放款"
    width="100%"
  >
    <el-form
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-width="100px"
      label-position="top"
    >
      <el-form-item prop="name" label="放款对象">
        <el-input v-model="formData.name" placeholder="请输入" />
      </el-form-item>
      <el-form-item prop="amount" label="放款金额">
        <el-input-number v-model="formData.amount" :min="0" :max="1000000" />
      </el-form-item>
      <el-form-item prop="cycle" label="放款周期">
        <el-input-number v-model="formData.cycle" :min="0" :max="180" />
      </el-form-item>
      <el-form-item prop="createUser" label="放款人">
        <el-select
          v-model="formData.createUser"
          placeholder="Select"
          size="large"
        >
          <el-option label="阿利" value="阿利" />
          <el-option label="聂policeman" value="聂policeman" />
        </el-select>
      </el-form-item>
      <el-form-item prop="remark" label="备注">
        <el-input v-model="formData.remark" placeholder="请输入" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="mainDialogVisible = false">取消</el-button>
      <el-button type="primary" @click="handleCreateOrUpdate" :loading="loading"
        >确认</el-button
      >
    </template>
  </el-dialog>

  <!-- 新增/修改回款 -->
  <el-dialog
    v-model="subDialogVisible"
    @closed="resetSubForm"
    title="新增回款"
    width="100%"
  >
    <el-form
      ref="subFormRef"
      :model="subFormData"
      label-width="100px"
      label-position="top"
    >
      <el-form-item prop="costAmount" label="回款金额">
        <el-input-number
          v-model="subFormData.costAmount"
          :min="0"
          :max="1000000"
        />
      </el-form-item>
      <el-form-item prop="profitAmount" label="利润">
        <el-input-number
          v-model="subFormData.profitAmount"
          :min="0"
          :max="1000000"
        />
      </el-form-item>
      <el-form-item prop="remark" label="备注">
        <el-input v-model="subFormData.remark" placeholder="请输入" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="subDialogVisible = false">取消</el-button>
      <el-button
        type="primary"
        @click="handleCreateOrUpdateSub"
        :loading="loading"
        >确认</el-button
      >
    </template>
  </el-dialog>

  <!-- 审核 -->
  <el-dialog v-model="approvalDialogVisible" title="审核" width="100%">
    <el-form label-width="100px" label-position="top">
      <el-form-item prop="secret" label="密码">
        <el-input v-model="secret" placeholder="请输入密码" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="approvalDialogVisible = false">取消</el-button>
      <el-button type="primary" @click="haneleApproval" :loading="loading"
        >确认</el-button
      >
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import {
  type FormRules,
  type FormInstance,
  type TableColumnCtx,
  ElMessage,
} from "element-plus";
import { usePagination } from "@/hooks/usePagination";
import {
  Search,
  Refresh,
  CirclePlus,
  RefreshRight,
} from "@element-plus/icons-vue";
import {
  GetMainTableListApi,
  GetAnalysisTableListApi,
  ApprovalApi,
  AddMainTableApi,
  AddSubTableApi,
} from "@/api/home";
import { type MainTable, type SubTable } from "@/api/home/types/home";
import { reactive, ref, watch, onMounted, VNode, h } from "vue";
import { cloneDeep } from "lodash-es";
import { Row } from "element-plus/es/components/table-v2/src/components";

const loading = ref<boolean>(false);
const { paginationData, handleCurrentChange, handleSizeChange } =
  usePagination();

const currentDate = ref("");
const parseDate = () => {
  let date = new Date();
  let month = date.getMonth() + 1; // getMonth() 返回的月份是从0开始的
  let day = date.getDate();

  // 将月份和日期格式化为两位数
  let Tmonth = month < 10 ? "0" + month : month;
  let Tday = day < 10 ? "0" + day : day;

  currentDate.value = `${Tmonth}/${Tday}`;
};

//#region 回款
const DEFAULT_SubFORM_DATA: SubTable = {
  id: undefined,
  remark: "",
  costAmount: 0,
  profitAmount: 0,
  mainId: undefined,
  status: 0,
};
const subFormData = ref<SubTable>(cloneDeep(DEFAULT_SubFORM_DATA));
const subFormRef = ref<FormInstance | null>(null);
const subDialogVisible = ref<boolean>(false);
const handleRecycle = (row: MainTable) => {
  subDialogVisible.value = true;
  subFormData.value = cloneDeep(DEFAULT_SubFORM_DATA);
  subFormData.value.mainId = row.id;
};

//#region 主表统计
interface SummaryMethodProps<T = MainTable> {
  columns: TableColumnCtx<T>[];
  data: T[];
}
const getSummaries = (param: SummaryMethodProps) => {
  const { columns, data } = param;
  const sums: (string | VNode)[] = [];
  columns.forEach((column, index) => {
    if (index === 1) {
      sums[index] = h("div", { style: { textDecoration: "underline" } }, [
        "总计",
      ]);
      return;
    }

    if (column.property == "amount") {
      const values = data.map((item) => Number(item[column.property]));
      if (!values.every((value) => Number.isNaN(value))) {
        sums[index] = `${values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!Number.isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0)}`;
      } else {
        sums[index] = "N/A";
      }
    }
  });

  return sums;
};
//#endregion

//#region 主表统计
interface SubSummaryMethodProps<T = MainTable> {
  columns: TableColumnCtx<T>[];
  data: T[];
}
const getSubSummaries = (param: SummaryMethodProps) => {
  const { columns, data } = param;
  const sums: (string | VNode)[] = [];
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = h("div", { style: { textDecoration: "underline" } }, [
        "明细总计",
      ]);
      return;
    }

    const values = data.map((item) => Number(item[column.property]));
    if (!values.every((value) => Number.isNaN(value))) {
      sums[index] = `${values.reduce((prev, curr) => {
        const value = Number(curr);
        if (!Number.isNaN(value)) {
          return prev + curr;
        } else {
          return prev;
        }
      }, 0)}`;
    } else {
      sums[index] = "N/A";
    }
  });

  return sums;
};
//#endregion

const handleCreateOrUpdateSub = () => {
  subFormRef.value?.validate((valid: boolean, fields) => {
    if (!valid) return console.error("表单校验不通过", fields);
    loading.value = true;
    AddSubTableApi(subFormData.value)
      .then(() => {
        ElMessage.success("操作成功");
        subDialogVisible.value = false;
        getMainTableData();
      })
      .finally(() => {
        loading.value = false;
      });
  });
};
const resetSubForm = () => {
  subFormRef.value?.clearValidate();
  subFormData.value = cloneDeep(DEFAULT_SubFORM_DATA);
};
//#endregion

//#region 增
const DEFAULT_FORM_DATA: MainTable = {
  id: undefined,
  name: "",
  remark: "",
  createUser: "",
  amount: 0,
  cycle: 0,
};
const mainDialogVisible = ref<boolean>(false);
const formRef = ref<FormInstance | null>(null);
const formData = ref<MainTable>(cloneDeep(DEFAULT_FORM_DATA));
const formRules: FormRules<MainTable> = {
  name: [{ required: true, trigger: "blur", message: "请输入放款人" }],
};
const handleCreateOrUpdate = () => {
  formRef.value?.validate((valid: boolean, fields) => {
    if (!valid) return console.error("表单校验不通过", fields);
    loading.value = true;
    AddMainTableApi(formData.value)
      .then(() => {
        ElMessage.success("操作成功");
        mainDialogVisible.value = false;
        getMainTableData();
      })
      .finally(() => {
        loading.value = false;
      });
  });
};
const resetForm = () => {
  formRef.value?.clearValidate();
  formData.value = cloneDeep(DEFAULT_FORM_DATA);
};
//#endregion

//#region 查
const tableData = ref<MainTable[]>([]);
const searchFormRef = ref<FormInstance | null>(null);
const searchData = reactive({
  key: "",
});
const getMainTableData = () => {
  loading.value = true;
  GetMainTableListApi({
    page: paginationData.currentPage,
    limit: paginationData.pageSize,
    key: searchData.key || "",
  })
    .then(({ result }) => {
      paginationData.total = result.count;
      tableData.value = result.data;
    })
    .catch(() => {
      tableData.value = [];
    })
    .finally(() => {
      loading.value = false;
      getAnalysisData();
    });
};
const handleSearch = () => {
  paginationData.currentPage === 1
    ? getMainTableData()
    : (paginationData.currentPage = 1);
};
const resetSearch = () => {
  searchFormRef.value?.resetFields();
  handleSearch();
};

/** 监听分页参数的变化 */
watch(
  [() => paginationData.currentPage, () => paginationData.pageSize],
  getMainTableData,
  { immediate: true }
);
//#endregion

//#region 审核
const secret = ref<string>("");
const approvalId = ref<number | undefined>(0);
const approvalDialogVisible = ref<boolean>(false);

const showApprovalDialog = (row: SubTable) => {
  approvalId.value = row.id;
  approvalDialogVisible.value = true;
};

const haneleApproval = () => {
  loading.value = true;
  ApprovalApi(approvalId.value, secret.value)
    .then(() => {
      ElMessage.success("审核成功");
      getMainTableData();
    })
    .finally(() => {
      loading.value = false;
      approvalDialogVisible.value = false;
    });
};
//#endregion

//#region 获取分析数据
const totalAmount = ref<number | undefined>(0);
const totalPay = ref<number | undefined>(0);
const totalIncome = ref<number | undefined>(0);
const totalProfit = ref<number | undefined>(0);

const getAnalysisData = () => {
  GetAnalysisTableListApi().then(({ result }) => {
    totalAmount.value = result.totalAmount;
    totalPay.value = result.totalPay;
    totalIncome.value = result.totalIncome;
    totalProfit.value = result.totalProfit;
  });
};
//#endregion

onMounted(() => {
  parseDate();
  getAnalysisData();
});
</script>
<style lang="scss">
.toolbar-wrapper {
  display: flex;
  justify-content: space-between;
}
.analysisTb {
  th {
    width: 100px;
  }
}
</style>
