import { request } from "@/utils/service";
import type * as HomeModel from "./types/home";

export function GetAnalysisTableListApi() {
  return request<HomeModel.ResponseModel<HomeModel.AnalysisModel>>({
    url: "/api/Dk/GetAnalysisTableList",
    method: "get"
  });
}
export function GetMainTableListApi(params: HomeModel.PageReq) {
  return request<HomeModel.ResponseModel<HomeModel.MainTableList>>({
    url: "/api/Dk/GetMainTableList",
    method: "get",
    params,
  });
}

export function GetSubTableListApi(params: HomeModel.PageReq) {
  return request<HomeModel.ResponseModel<HomeModel.SubTableList>>({
    url: "/api/Dk/GetSubTableList",
    method: "get",
    params,
  });
}

export function AddMainTableApi(data: HomeModel.MainTable) {
    return request<HomeModel.ResponseModel<string>>({
      url: "/api/Dk/AddMainTable",
      method: "post",
      data,
    });
  }
  
export function AddSubTableApi(data: HomeModel.SubTable) {
    return request<HomeModel.ResponseModel<string>>({
      url: "/api/Dk/AddSubTable",
      method: "post",
      data,
    });
  }
  
export function ApprovalApi(id?: number,secret?:string) {
  return request<HomeModel.ResponseModel<string>>({
    url: "/api/Dk/Approval",
    method: "get",
    params:{
      id,
      secret
    },
  });
}
